<template>
    <CModal
        title="対象者編集"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="対象者名"
                         id="edit-name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('name')"
                         v-model="form.name">
                </v-input>
                <v-input label="住所"
                         id="edit-address"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('address')"
                         v-model="form.address">
                </v-input>
                <v-input label="生年月日"
                         id="edit-birthday"
                         type="date"
                         placeholder="入力必須"
                         :errors="hasErrors('birthday')"
                         v-model="form.birthday">
                </v-input>
                <v-select id="edit-facility"
                          label="施設"
                          :errors="hasErrors('facility_id')"
                          v-model="form.facility_id">
                    <option value="" :selected="form.facility_id === ''">選択してください</option>
                    <option :value="facility.id" :selected="form.facility_id === facility.id" v-for="facility in facilities"
                            :key="facility.id">
                        {{ facility.attributes.name }}
                    </option>
                </v-select>
                <v-text-area label="要介護・障害認定の状況"
                             id="edit-remark"
                             placeholder="入力してください"
                             :errors="hasErrors('remark')"
                             v-model="form.remark">
                </v-text-area>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updatePerson" :disabled="sending">更新</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VTextArea from "@/components/Forms/VTextArea";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "EditPersonModal",
    components: {VSelect, VTextArea, VInput},
    mixins: [ApiRouter, ErrorHandler],
    props: ['facilities'],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            form: {
                name: '',
                address: '',
                birthday: '',
                facility_id: '',
                remark: ''
            }
        }
    },
    methods: {
        openModal: function (person) {
            this.isOpened = true;
            this.initForm(person);
            this.clearErrors();
        },
        initForm: function (person) {
            this.id = person.id;
            this.form.name = person.attributes.name;
            this.form.address = person.attributes.address;
            this.form.birthday = person.attributes.birthday;
            this.form.facility_id = person.attributes.facility_id;
            this.form.remark = person.attributes.remark;
        },
        updatePerson: function () {
            this.showIndicator('更新中');
            this.sending = true;

            axios.patch(this.routes.person(this.id), this.form)
                .then(response => {
                    this.$emit('person-has-updated', response.data.data);
                    this.showSuccessPopup('更新しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
