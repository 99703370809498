<template>
    <CModal
        title="対象者削除"
        color="dark"
        size="sm"
        :show.sync="isOpened">
        <div class="py-1">対象者名：{{ name }}</div>
        <div class="py-1">生年月日：{{ birthday }}</div>
        <div class="py-1">施設名：{{ facilityName }}</div>
        <div class="py-1">備考：{{ remark }}</div>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteConfirm" :disabled="sending">削除</button>
        </template>
    </CModal>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DeletePersonModal",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            name: '',
            birthday: '',
            facilityName: '',
            remark: ''
        }
    },
    methods: {
        openModal: function (person) {
            this.isOpened = true;
            this.id = person.id;
            this.name = person.attributes.name;
            this.birthday = person.attributes.birthday;
            this.facilityName = person.relationships.facility.attributes.name;
            this.remark = person.attributes.remark;
        },
        deletePerson: function () {
            this.showIndicator('削除中');
            this.sending = true;

            axios.delete(this.routes.person(this.id))
                .then(response => {
                    this.$emit('person-has-deleted', response.data.data);
                    this.showSuccessPopup('削除しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteConfirm: function () {
            this.showDeleteConfirmPopup(() => {
                this.deletePerson();
            })
        }
    }
}
</script>

<style scoped>

</style>
