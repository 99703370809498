<template>
    <div class="container-fluid">
        <div class="row pb-3">
            <div class="col-12">
                <h3>#対象者一覧</h3>
            </div>
        </div>
        <div class="row pb-2">
            <div class="col-10 float-left">
                <form>
                    <div class="form-row w-50">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="対象者名を入力" v-model="form.name">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="施設名を入力" v-model="form.facility_name">
                        </div>
                        <div class="col">
                            <a href="javascript:void(0)" class="btn btn-primary" @click="searchPersons">検索</a>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-2 float-right">
                <button type="button"
                        class="btn btn-outline-dark float-right"
                        @click="openCreateModal">＋
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <person-list :persons="persons"
                             :facilities="facilities"
                             :meta="meta"
                             @paginate="paginate"
                             @person-has-updated="updatePerson"
                             @person-has-deleted="deletePerson"
                             @person-has-restored="restorePerson">
                </person-list>
            </div>
        </div>
        <create-person-modal ref="createModal" :facilities="facilities" @person-has-created="createPerson"></create-person-modal>
    </div>
</template>

<script>
import PersonList from "@/components/Persons/PersonList/PersonList";
import CreatePersonModal from "@/components/Persons/CreatePersonModal/CreatePersonModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "Persons",
    components: {CreatePersonModal, PersonList},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            persons: {},
            facilities: {},
            meta: {},
            form: {
                name: '',
                facility_name: ''
            }
        }
    },
    created: function () {
        this.loadPersons();
        this.loadFacilities();
    },
    methods: {
        loadPersons: function () {
            axios.get(this.routes.persons)
                .then(response => {
                    this.persons = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        loadFacilities: function () {
            axios.get(this.routes.facilityAll)
                .then(response => {
                    this.facilities = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        searchPersons: function () {
            axios.get(this.routes.persons, {
                params: {
                    name: this.form.name,
                    facility_name: this.form.facility_name,
                }
            })
                .then(response => {
                    this.persons = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        paginate: function (page) {
            axios.get(this.routes.persons, {
                params: {
                    page: page,
                    name: this.form.name,
                    facility_name: this.form.facility_name,
                }
            })
                .then(response => {
                    this.persons = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        openCreateModal: function () {
            this.$refs['createModal'].openModal();
        },
        createPerson: function (person) {
            this.persons.unshift(person);
        },
        updatePerson: function ($event) {
            const index = this.persons.findIndex(function (person) {
                return person.id === $event.id;
            });
            if (index !== -1) {
                this.persons.splice(index, 1, $event);
            }
        },
        deletePerson: function ($event) {
            const index = this.persons.findIndex(function (person) {
                return person.id === $event.id;
            });
            this.persons.splice(index, 1);

            this.persons.push($event);
        },
        restorePerson: function ($event) {
            const index = this.persons.findIndex(function (person) {
                return person.id === $event.id;
            });
            if (index !== -1) {
                this.persons.splice(index, 1, $event);
            }
        },
    }
}
</script>

<style scoped>

</style>
