<template>
    <div>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 10%">対象者名</th>
                <th style="width: 30%">住所</th>
                <th style="width: 10%">生年月日</th>
                <th style="width: 10%">施設名</th>
                <th style="width: 20%">要介護・障害認定の状況</th>
                <th style="width: 10%">編集</th>
                <th style="width: 10%">削除</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="person in persons">
                <tr :class="{'is-deleted': (person.attributes.deleted_at !== null)}">
                    <td>{{ person.attributes.name }}</td>
                    <td>{{ person.attributes.address }}</td>
                    <td>{{ person.attributes.birthday | moment('YYYY/MM/DD') }}</td>
                    <td>{{ person.relationships.facility.attributes.name }}</td>
                    <td>{{ person.attributes.remark }}</td>
                    <template v-if="person.attributes.deleted_at === null">
                        <td><a href="javascript:void(0)" @click="openEditModal(person)">編集</a></td>
                        <td><a href="javascript:void(0)" @click="openDeleteModal(person)">削除</a></td>
                    </template>
                    <template v-else>
                        <td colspan="2" class="text-center"><a href="javascript:void(0)" @click="restoreConfirm(person)">復元</a></td>
                    </template>
                </tr>
            </template>
            </tbody>
        </table>
        <pagination :meta="meta" @paginate="$emit('paginate', $event)"></pagination>
        <edit-person-modal ref="editModal"
                           :facilities="facilities"
                           @person-has-updated="$emit('person-has-updated', $event)">
        </edit-person-modal>
        <delete-person-modal ref="deleteModal"
                             @person-has-deleted="$emit('person-has-deleted', $event)">
        </delete-person-modal>
    </div>
</template>

<script>
import EditPersonModal from "@/components/Persons/EditPersonModal/EditPersonModal";
import DeletePersonModal from "@/components/Persons/DeletePersonModal/DeletePersonModal";
import Pagination from "@/components/Pagination/Pagination";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "PersonList",
    components: {DeletePersonModal, EditPersonModal, Pagination},
    mixins: [ApiRouter, ErrorHandler],
    props: ['persons', 'facilities', 'meta'],
    methods: {
        openEditModal: function (person) {
            this.$refs['editModal'].openModal(person);
        },
        openDeleteModal: function (person) {
            this.$refs['deleteModal'].openModal(person);
        },
        restorePerson: function (person) {
            this.showIndicator('復元中');

            axios.patch(this.routes.personRestore(person.id))
                .then(response => {
                    this.showSuccessPopup('復元しました');
                    this.$emit('person-has-restored', response.data.data);
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        restoreConfirm: function (person) {
            this.showRestoreConfirmPopup(() => {
                this.restorePerson(person);
            })
        }
    }
}
</script>

<style src="./PersonList.scss" lang="scss" scoped/>
